/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql, Link } from 'gatsby'
import { Box, Flex } from 'rebass'
import { MdChevronRight, MdLocalDrink, MdSwapCalls, MdPerson } from "react-icons/md"

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero } from '../../components/elements'

const Index = ({ data }) => {

  const page = data.prismic.services

  return (
    <Layout>
      <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
      <PubHero title="Services" />
      <Section>
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">


            {/* Water */}
            <Box width={['full', 4 / 5, 3 / 4]} color="white">
              <Box sx={{ bg: 'blue.5', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 sx={{ margin: 0 }}>Water Related</h2><MdLocalDrink sx={{ fill: 'blue.6', fontSize: '3em' }} />
              </Box>
              <Box sx={{
                bg: 'blue.6', p: 4
              }}>
                <p sx={{ marginBottom: 0 }}>Water is the most integral part of our individual survivals and the Earth. While there is an abundant water supply on the planet, we cannot survive unless we are using a clean and reliable source. Thankfully, there are treatment facilities to ensure this, and in Parkersburg, West Virginia, there is PUB. Our water is first collected, then undergoes a stringent treatment process, and finally is methodically distributed thanks to our superior people and equipment.</p>
                <Link to="services/water" sx={{ color: 'white', textDecoration: 'none', display: 'block', mt: 2, display: 'flex', alignItems: 'center', fontWeight: 'bold', "&:hover": { color: 'white', textDecoration: 'underline' } }}>Learn More <MdChevronRight /></Link>
              </Box>
            </Box>

            {/* Sewer */}
            <Box width={['full', 4 / 5, 3 / 4]} color="white">
              <Box sx={{ bg: 'yellow.5', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 sx={{ margin: 0 }}>Sewer Related</h2><MdSwapCalls sx={{ fill: 'yellow.6', fontSize: '3em' }} />
              </Box>
              <Box sx={{
                bg: 'yellow.6', p: 4
              }}>
                <p sx={{ marginBottom: 0 }}>While often taken for granted, sewer, or wastewater, is essential to the health and wellness of any community. PUB has made continual improvements to our sewer treatment and collection facilities to safely handle and make sanitary again. With our focus on bettering water and sanitary sewer system standards in Parkersburg, it is clear that the caliber of what we do is sure to make an even bigger, positive impact on the lives of thousands.</p>
                <Link to="services/sewer" sx={{ color: 'white', textDecoration: 'none', display: 'block', mt: 2, display: 'flex', alignItems: 'center', fontWeight: 'bold', "&:hover": { color: 'white', textDecoration: 'underline' } }}>Learn More <MdChevronRight /></Link>
              </Box>
            </Box>

            {/* Consultation/Design */}
            <Box width={['full', 4 / 5, 3 / 4]} color="white">
              <Box sx={{ bg: 'green.5', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 sx={{ margin: 0 }}>Consultation/Design</h2><MdPerson sx={{ fill: 'green.6', fontSize: '3em' }} />
              </Box>
              <Box sx={{
                bg: 'green.6', p: 4
              }}>
                <p sx={{ marginBottom: 0 }}>PUB works for the people of Parkersburg. Our services and resources are invaluable, and we are happy to assist customers with water and sewer in any way we can. Our consultation staff includes surveyors, draftsmen, and computer aided design technicians. We work with contractors, developers, and homeowners on designing water and wastewater extensions to ensure proper function and location.</p>
                <Link to="services/consultation-design" sx={{ color: 'white', textDecoration: 'none', display: 'block', mt: 2, display: 'flex', alignItems: 'center', fontWeight: 'bold', "&:hover": { color: 'white', textDecoration: 'underline' } }}>Learn More <MdChevronRight /></Link>
              </Box>
            </Box>

          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Index

export const query = graphql`
query ServicesQuery {
  prismic {
    services(lang: "en-us", uid: "services") {
      title
      content
      meta_title
      meta_description
      _linkType
    }
  }
}
`